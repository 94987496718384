import styled from 'styled-components';
import styles from 'constants/styles';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-left: ${({ marginLeft }) => marginLeft ?? 24}px;
  ${({ hasDisabledOpacity }) => hasDisabledOpacity && `opacity: 0.3;`}
  &:first-child {
    margin: 0;
  }
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
  color: ${styles.colors.GRAY};
  margin-bottom: 5px;
`;

export const getSelectMultipleStyles = (theme) => ({
  control: (base) => ({
    ...base,
    boxShadow: 'none',
    padding: '6px 0',
    fontFamily: 'Roboto',
    width: '100%',
    backgroundColor: theme.colors.INPUT_BG,
    border: 'none',
    borderRadius: '4px',
    color: theme.colors.INPUT_COLOR,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.14,
    outline: '0 none'
  }),
  singleValue: (base) => ({
    ...base,
    color: theme.colors.MULTI_VALUE_LABEL,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.3
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: theme.colors.MULTI_VALUE_LABEL,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.3
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: theme.colors.DARK_2,
    borderRadius: 20,
    padding: '4px 4px 4px 12px',
    height: 24,
    alignItems: 'center',
    margin: '4px'
  }),

  multiValueRemove: (base) => ({
    ...base,
    borderRadius: '50%',
    maxHeight: '16px',
    margin: '0 0 0 16px',
    padding: '0',
    cursor: 'pointer',
    ':hover': 'unset'
  }),
  placeholder: (base) => ({
    ...base,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.14,
    color: theme.colors.DARK_GREY_2
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: theme.colors.DROPDOWN_MENU,
    boxShadow: theme.boxShadows.THIN_DARK_3,
    borderRadius: '4px',
    color: theme.colors.SELECT_ITEM_TEXT,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 1.3,
    margin: '8px 0 0 0',
    width: '100%'
  }),
  menuList: (base) => ({
    ...base,
    width: '100%',
    'div:not(:last-child)': { borderBottom: `1px solid ${theme.colors.DARK_GREY_2}` }
  }),
  option: (base) => ({
    ...base,
    backgroundColor: 'unset',
    position: 'relative',
    margin: '0 16px',
    padding: '12px 0',
    cursor: 'pointer',
    color: 'inherit !important',
    width: 'calc(100% - 32px)',
    ':hover': {
      margin: '0',
      padding: '12px 16px',
      borderBottom: `1px solid ${theme.colors.DROPDOWN_ITEM_BORDER_HOVER} !important`,
      backgroundColor: theme.colors.DROPDOWN_ITEM_BORDER_HOVER,
      width: '100%'
    },
    ':hover:after': {
      backgroundColor: theme.colors.DROPDOWN_ITEM_BORDER_HOVER,
      top: -1,
      left: 0,
      width: '100%',
      height: '1px',
      position: 'absolute',
      content: '""'
    }
  }),

  indicatorsContainer: (base) => ({
    ...base,
    alignSelf: 'self-start',
    alignItems: 'flex-start'
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: theme.colors.DARK_2
  }),
  input: (base) => ({
    ...base,
    color: theme.colors.INPUT_COLOR,
    '& > div > input': {
      minWidth: '80px'
    }
  })
});
