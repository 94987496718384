export default createActionTypes(
  // full (FETCH, SUCCESS, ERROR, DISMISS)
  [
    'CREATE_QUOTATION',
    'GET_AGENT_INSTANCE',
    'GET_AGENT_CHARGES',
    'GET_AGENT_LIST',
    'GET_BADGES',
    'GET_CSV_FILE',
    'GET_CUSTOMER_INSPECTION_REPORT',
    'GET_CUSTOMER_INSTANCE',
    'GET_CUSTOMER_LOCATION',
    'GET_CUSTOMER_LIST',
    'GET_MEDIA_URL',
    'GET_MEDIA_URL_FOR_LIST',
    'GET_MEDIA_URLS',
    'GET_MEDIA_URLS_FOR_LIST',
    'GET_PROVIDER_INSTANCE',
    'GET_PROVIDER_LIST',
    'GET_SERVICE_LIST',
    'GET_SERVICE_INSTANCE',
    'GET_TASK_PAYMENT_LIST',
    'GET_TASK_QUOTATION_LIST',
    'GET_USER_SERVICE_LIST',
    'SIGN_SERVICE_IMAGE_URLS',
    'CREATE_PAYMENT',
    'UPDATE_PAYMENT',
    'DELETE_COGNITO_USER',
    'DELETE_PAYMENT',
    'DELETE_QUOTATION',
    'GET_SERVICE_LOCATION',
    'UPDATE_JOB_FIELD',
    'GET_SERVICE_TAB_LIST',
    'UPDATE_PROVIDER_INSTANCE',
    'GET_SERVICE_PROGRESS_LIST',
    'GET_SERVICE_NOTE_LIST',
    'CREATE_SERVICE_NOTE',
    'GET_USER_NOTE_LIST',
    'CREATE_USER_NOTE',
    'DELETE_SERVICE_NOTE',
    'DELETE_USER_NOTE',
    'GET_TASK_MESSAGE_LIST',
    'CREATE_TASK_MESSAGE',
    'GET_SUPPORT_MESSAGE_LIST',
    'CREATE_SUPPORT_MESSAGE',
    'GET_AVATAR',
    'GET_AVATAR_ORIGINAL',
    'UPLOAD_CUSTOMER_INSPECTION_REPORT',
    'GET_TASK_LINK_PREVIEWS',
    'GET_SUPPORT_LINK_PREVIEWS',
    'GET_LINK_PREVIEWS',
    'CREATE_PAYOUT',
    'GET_TASK_MESSAGE_BY_ID',
    'GET_SUPPORT_MESSAGE_BY_ID',
    'UPDATE_SERVICE_NOTE',
    'UPDATE_USER_NOTE',
    'BUNDLE_SERVICES',
    'UPDATE_CUSTOMER_LOCATION',
    'UPDATE_CUSTOMER_INSTANCE',
    'UPDATE_AGENT_INSTANCE',
    'UPDATE_QUOTATION',
    'GET_USER_INSTANCE',
    'UPDATE_USER_INSTANCE',
    'UPLOAD_INSPECTION_REPORT',
    'GET_UNREAD_MESSAGED_BY_CATEGORIES',
    'GET_NOTIFICATION_LIST',
    'GET_CAMPAIGNS_LIST',
    'GET_CAMPAIGN_BY_ID',
    'SEND_TEST_CAMPAIGN_EVENT',
    'DELETE_CAMPAIGN',
    'EDIT_CAMPAIGN',
    'DELETE_PERSISTENT_NOTIFICATION',
    'GET_PAYMENT_LIST',
    'GET_QUOTATION_LIST',
    'GET_PROVIDER_REVIEWS',
    'GET_CUSTOMER_REVIEWS',
    'GET_PROVIDER_AREAS',
    'GET_REVIEW_LIST',
    'GET_CONVERSATION_LIST',
    'UPDATE_CONVERSATION',
    'GET_COMMUNICATION_SMS_LIST',
    'CREATE_COMMUNICATION_SMS',
    'UPDATE_REVIEW',
    'DELETE_PROVIDER_REVIEW',
    'DUPLICATE_TASK',
    'GET_REWARDS',
    'GET_PROMO_CODE_LIST',
    'CREATE_PROMO_CODE',
    'UPDATE_PROMO_CODE',
    'EXPIRE_PROMO_CODE',
    'CREATE_REWARD',
    'DELETE_REWARD',
    'GET_REFERRAL_LIST',
    'GET_AGENT_LOCATION_LIST',
    'GET_ANALYTIC_METRIC',
    'GET_ANALYTIC_SNAPSHOT',
    'GET_ADMIN_LIST',
    'GET_ADMIN_INFO',
    'GET_PRO_ASSIGN_BUNDLE',
    'UPDATE_REQUEST',
    'CREATE_REQUEST',
    'SCHEDULE_VISIT',
    'UNBUNDLE_TASK',
    'SNOOZE_TASK',
    'DELETE_SUPPORT_MESSAGE',
    'UPDATE_SUPPORT_MESSAGE',
    'DELETE_TASK_MESSAGE',
    'UPDATE_TASK_MESSAGE',
    'GET_SKILLS',
    'CREATE_SKILL',
    'UPDATE_SKILL',
    'GET_CUSTOMER_REFERRALS',
    'MANAGE_CUSTOMER_REWARD',
    'GET_CUSTOMER_DOBBY_CASH',
    'GET_USER_QUESTIONS',
    'CLOSE_PAYMENT',
    'GET_OWNER_REPORT',
    'GET_CUSTOMER_DOBBY_CASH_TRANSACTIONS',
    'CHAT_EXTEND_OR_CLOSE',
    'CREATE_CAMPAIGN'
  ],
  // single
  [
    'ADD_NOTIFICATION_TO_PERSISTENT_LIST',
    'CHANGE_ACTIVE_TAB',
    'CHANGE_HAS_GO_BACK_BUTTON',
    'CLOSE_MODAL',
    'OPEN_MODAL',
    'REFRESH_TASK_INFO',
    'REMOVE_NOTIFICATION_ALERT',
    'RESET_PLAN',
    'RESET_TASK_UNREAD_MESSAGES_MARK',
    'RESET_USER_UNREAD_MESSAGES_MARK',
    'RESET_USER_UNREAD_MESSAGES_DIVIDER',
    'RESET_TASK_UNREAD_MESSAGES_DIVIDER',
    'SAVE_LOCATION_SEARCH',
    'SET_FILTERED_ADMIN_LIST',
    'SET_FILTERED_GEOLOCATION_LIST',
    'SET_ACTIVE_MENU_ITEM',
    'SET_ANALYTICS_PERIOD',
    'SET_ANALYTICS_PERIOD_DATE',
    'SET_DEV_ENVIRONMENT',
    'SET_DARK_MODE',
    'SET_ACTIVE_TIMEZONE',
    'SET_PLAN',
    'SET_TEST_TASKS_VISIBILITY',
    'SET_SERVICE_PAID',
    'SHOW_NOTIFICATION_ALERT',
    'GET_TWILIO_VOICE_TOKEN',
    'TOGGLE_HAS_NOTIFICATIONS_POPUP',
    'TOGGLE_INVOICE_FORM'
  ]
);

function createActionTypes(full, single) {
  let types = {};
  full.forEach((type) => {
    types[`${type}_FETCH`] = `${type}_FETCH`;
    types[`${type}_SUCCESS`] = `${type}_SUCCESS`;
    types[`${type}_ERROR`] = `${type}_ERROR`;
    types[`DISMISS_${type}_ERROR`] = `DISMISS_${type}_ERROR`;
  });
  single.forEach((type) => {
    types[type] = type;
  });
  return types;
}
