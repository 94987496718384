export const adminPermissionStatuses = {
  ADMIN: {
    POSITION_ID: 1,
    POSITION_NAME: 'admin'
  },
  ENGINEER: {
    POSITION_ID: 2,
    POSITION_NAME: 'engineer'
  },
  OPERATOR: {
    POSITION_ID: 3,
    POSITION_NAME: 'operator'
  },
  EXPERIENCE: {
    POSITION_ID: 4,
    POSITION_NAME: 'experience'
  },
  MARKETING: {
    POSITION_ID: 5,
    POSITION_NAME: 'marketing'
  },
  ANALYST: {
    POSITION_ID: 6,
    POSITION_NAME: 'analyst'
  },
  JOB_OWNER: {
    POSITION_ID: 7,
    POSITION_NAME: 'job_owner'
  }
};

export const csvFileTypes = {
  REFERRAL: 0,
  TASK: 1,
  CUSTOMER: 2,
  SERVICE_PROVIDER: 3,
  PAYMENT: 4,
  QUOTATION: 5
};

export const mediaObjectTypes = {
  PAYMENT_IMAGES: 'paymentImageUrls',
  PAYMENT_PDF: 'paymentPDFUrls',
  TASK_IMAGES: 'taskImageUrls',
  QUOTATION_IMAGES: 'quotationImageUrls',
  QUOTATION_PDF: 'quotationPDFUrls',
  PAYMENT_NOTES: 'paymentNotes'
};

export const errorTypes = {
  REQUIRED: 'required'
};

export const deviceNotificationStatus = {
  DEFAULT: {
    TYPE: -1,
    NAME: 'Off'
  },
  OFF: {
    TYPE: 0,
    NAME: 'Off by user'
  },
  ON: {
    TYPE: 1,
    NAME: 'On'
  },
  PROVISIONAL: {
    TYPE: 2,
    NAME: 'On (without sound)'
  }
};

export const environments = {
  DEVELOP: {
    TYPE: 'DEVELOP',
    ENDPOINT: 'https://develop.api.hellodobby.com/v0'
  },
  LOCAL: {
    TYPE: 'LOCAL',
    ENDPOINT: 'http://127.0.0.1:5000'
  },
  PRODUCT: {
    TYPE: 'PRODUCT',
    ENDPOINT: 'https://product.api.hellodobby.com/v0'
  },
  STAGING: {
    TYPE: 'STAGING',
    ENDPOINT: 'https://staging.api.hellodobby.com/v0'
  }
};

export const searchHighlightTypes = {
  DEFAULT: 'default',
  PHONE_NUMBER: 'phone_number',
  FULL_TEXT: 'full_text',
  EXACT: 'exact'
};

export const searchPrefixes = {
  PROVIDER_NAME: {
    TYPE: 'PROVIDER_NAME',
    PREFIX: 'p='
  },
  CUSTOMER_NAME: {
    TYPE: 'CUSTOMER_NAME',
    PREFIX: 'h='
  },
  ID: {
    TYPE: 'ID',
    PREFIX: 'id='
  },
  MESSAGE: {
    TYPE: 'MESSAGE',
    PREFIX: 'm=',
    HAS_PREFIX_BACKEND_EXCLUDE: true
  },
  PHONE_NUMBER: {
    TYPE: 'PHONE_NUMBER',
    PREFIX: '+',
    IS_SEARCH_TERM_PREFIX: true
  }
};

export const mediaTypes = {
  BEFORE: 1, // job, final payment before
  AFTER: 2 // pro report, pro review, quote, deposit, partial, final payment after
};
