import styled from 'styled-components';

export const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  top: 8px;
  right: 8px;
  border-radius: 50%;
  width: 16px;
  height: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.DARK_GREY_2};
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:first-child {
    margin: 0;
  }
`;

export const TextareaRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ marginTop }) => marginTop ?? 5}px;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.DARK_GREY_3 : theme.colors.GRAY)};

  ${({ small, theme, disabled }) =>
    small &&
    `
    font-weight: 500;
    font-size: 10px;
    line-height: 1.2;
    color: ${disabled ? theme.colors.DARK_GREY_3 : theme.colors.DARK_GREY_2};
  `}

  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ color }) => color && ` color: ${color};`}
`;

export const Error = styled(Label)`
  color: ${({ theme }) => theme.colors.INPUT_ERROR};
  margin-top: 5px;
`;

export const StyledTextarea = styled.textarea`
  display: inline-block;
  outline: 0 none;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.INPUT_BG};
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.INPUT_COLOR};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.14;
  padding: 8px ${({ reset }) => (reset ? '32px' : '16px')} 8px 16px;
  width: 100%;
  resize: none;
  flex: 1;
  margin-bottom: 0;
  margin-top: ${({ marginTop }) => marginTop ?? 0}px;
  min-width: ${({ width }) => width}px;
  &::placeholder {
    color: ${({ theme }) => theme.colors.INPUT_PLACEHOLDER};
  }

  ${({ invalid, theme }) =>
    invalid &&
    `
    border: 1px solid ${theme.colors.INPUT_ERROR};
  `}

  &:active:not([disabled]),
  &:focus:not([disabled]) {
    outline: 0 none;
    background: ${({ theme }) => theme.colors.INPUT_FOCUS_BG};
    border: none;

    ${({ invalid, theme }) =>
      invalid &&
      `
      border: 1px solid ${theme.colors.INPUT_ERROR};
  `}
  }

  &[disabled] {
    box-shadow: none;
    background: #282e30;
    color: ${({ theme }) => theme.colors.DARK_GREY_3};
  }

  &[disabled]::placeholder {
    color: ${({ theme }) => theme.colors.DARK_GREY_3};
  }
`;
